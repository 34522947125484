.sidebar-list {
  overflow-y: scroll;
  padding-right: 10px;
  position: relative;
  opacity: 1;
  height: 100%;
}

.list-header {
  position: absolute;
  left: 0;
  top: 0;
  height: 55px;
  width: 100%;
  z-index: 1;
  background-color: #fff;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview-list-item {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 15px 10px;
  cursor: pointer;
}

.preview-list-item:hover {
  color: white;
  background-color: #70b8ff;
}

.preview-list-item.active:not(.dragging) {
  color: white;
  background-color: #3096fc;
  cursor: grab;
}

.slide-index {
  margin-right: 15px;
  font-variant-numeric: tabular-nums;
}

.slide-thumbnail {
  width: 95%;
}

.delete-icon {
  position: absolute;
  height: 20px;
  width: 20px;
  top: -10px;
  left: -10px;
  border-radius: 10px;
  background-color: red;
  color: "white" !important;
  font-size: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}



.additional-sidebar-tile {
  width: 70%;
  aspect-ratio: 1.77 / 1;
  flex-shrink: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 0px;
  color: #6c757d;
  border: 1px solid #6c757d;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 20%;
}
