.editor-component {
    padding: 5px;
    cursor: pointer;
}

.editor-component:hover:not(.selected) {
    box-shadow: 0 0 4px -1px #007bff;
}

.editor-component.selected {
    border: 1px solid #007bff;
    cursor: 
    move;
}