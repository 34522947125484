.header {
  display: flex;
  width: 100%;
  padding: 10px 15px;
  background-color: #555555;
  line-height: 0.8;
  color: white;
}

.logo {
  display: flex;
  align-items: center;
  flex: 1;
}
