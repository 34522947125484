.select-list {
  display: flex;
  list-style-type: none;
  margin: 0;
  max-width: calc(50vw - 10px);
  width: 100%;
  gap: 20px;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  flex-shrink: 0;
}

.thumbs-list {
  overflow-x: scroll;
}

.pills-list {
  flex-wrap: wrap;
  row-gap: 7px;
}

.pills-list>li:hover {
  filter: opacity(0.85);
}

.select-list::-webkit-scrollbar {
  display: none;
}

.select-list-item {
  display: flex;
  width: 140px;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
  position: relative;
}

.select-list-item .thumb-image-wrapper {
  position: relative;
}

.thumb-image-wrapper .item-thumb-image {
  width: 100%;
}

.select-list-item.active .thumb-image-wrapper::before {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  background-color: #3096fc;
  z-index: -1;
}

.select-list-item .item-thumb-title {
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 0 !important;
}

.select-list-item.active .item-thumb-title {
  color: #3096fc;
  white-space: nowrap;
  text-align: center;
  font-weight: 700;
}

.additional-tile {
  width: 140px;
  height: 79px;
  flex-shrink: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 20px;
  border-radius: 0px;
  color: #6c757d;
  border: 1px solid #6c757d;
}

.action-icon {
  position: absolute;
  height: 20px;
  width: 20px;
  right: 3px;
  border-radius: 10px;
  color: "white" !important;
  font-size: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10000;
}

.action-icon.delete {
  background-color: red;
  top: 3px;
}

.action-icon.copy {
  background-color: #6c757d;
  top: 25px;
}

.action-icon.edit {
  background-color: #6c757d;
  top: 47px;
}

.pill-icon {
  font-size: 1rem;
  margin-top: 3px;
  border-radius: 10px;
  cursor: pointer;
}