.backdrop {
  position: absolute;
  z-index: 100000;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.705);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-wrapper {
  background-color: rgb(230, 230, 230);
  height: 250px;
  width: 350px;
  padding: 10px;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
}
.modal-text {
  flex: 1;
}

.modal-footer {
}
