.accordion-wrapper {
    width: 100%;

}

.accordion-heading, .accordion-component{
    position: relative;
    padding: 10px 15px;
    width: 100%;
    min-height: 30px;
    max-height: 75px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.accordion-content {
    width: 100%;
    display: none;
    height: 0px;
    transition: max-height 0.5s ease;
}

.accordion-content.opened {
    display: block;
    height: auto;
}