.grid-container {
  height: calc(100vh - 61.32px);
  display: grid;
  grid-template-columns: minmax(0px, 175px) 1fr;
  grid-template-rows: 125px 1fr;
}

.grid-container:last-of-type {
  border-left: 1px solid #a1a1a1;
}

.toolbar {
  grid-column: 1 / 3;
  grid-row: 1 / 2;
  border-bottom: 1px solid #a1a1a1;
  padding: 10px;
  position: relative;
  overflow: hidden;
}

.toolbar .main-content {
  flex: 1;
}

.toolbar .secondary-content {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 250px;
  background-color: #f4f5f8;
  padding: 10px;
  box-shadow: -0.1rem 0 0.5rem rgba(0, 0, 0, 0.125) !important;
}

.slide-view {
  position: relative;
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 7px 0 10px -7px rgba(0, 0, 0, 0.1),
    inset 0 7px 10px -7px rgba(0, 0, 0, 0.1);
}

.slide-view.expended {
  grid-column: 1 / 3;
}

.slide-view>.delete-zone-outter {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 178.5px;
  height: 100px;
}

.zone-title {
  position: absolute;
  display: none;
  top: 50%;
  left: 50%;
  color: red;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  font-weight: 700;
  font-size: 1.2rem;
}

.zone-title.show {
  display: block;
}