.draggable-resize-handle {
    position: absolute;
    width: 9px;
    height: 9px;
    border: 1px solid #007bff;
    background: #ffffff;
    z-index: 10;
}

.draggable-resize-handle-nw {
    top: -5px;
    left: -5px;
    cursor: nw-resize;
}

.draggable-resize-handle-n {
    top: -5px;
    left: calc(50% - 5px);
    cursor: ns-resize;
}

.draggable-resize-handle-ne {
    top: -5px;
    right: -6px;
    cursor: ne-resize;
}

.draggable-resize-handle-e {
    top: calc(50% - 5px);
    right: -6px;
    cursor: ew-resize;
}

.draggable-resize-handle-se {
    bottom: -6px;
    right: -6px;
    cursor: se-resize;
}

.draggable-resize-handle-s {
    bottom: -6px;
    left: calc(50% - 5px);
    cursor: ns-resize;
}

.draggable-resize-handle-sw {
    bottom: -6px;
    left: -5px;
    cursor: sw-resize;
}

.draggable-resize-handle-w {
    top: calc(50% - 5px);
    left: -5px;
    cursor: ew-resize;
}
